import moment from "moment";
import { VueConstructor } from "vue";

moment.locale("zh-cn");

const globalFilters = {
  install(Vue: VueConstructor) {
    Vue.filter("date", (value: any, format: string) => {
      if (!value) {
        return null;
      }
      return moment(value).format(format || "YYYY-MM-DD HH:mm");
    });

    Vue.filter("duration", (value: any) => {
      return moment.duration(value).humanize();
    });

    Vue.filter(
      "round",
      (value: any, digits: number, lazyPrecision: boolean = true) => {
        if (!value || isNaN(value)) {
          value = 0;
        }
        let fixed = (+value).toFixed(digits);
        if (lazyPrecision) fixed = (+fixed).toString();
        return fixed;
      }
    );

    Vue.filter("sceneName", (value: any) => {
      return Vue.prototype.$sceneNames[value];
    });

    Vue.filter("bookingStatusName", (value: any) => {
      return Vue.prototype.$bookingStatusNames[value];
    });

    Vue.filter("cardTypeName", (value: any) => {
      return Vue.prototype.$cardTypeNames[value];
    });

    Vue.filter("cardStatusName", (value: any) => {
      return Vue.prototype.$cardStatusNames[value];
    });

    Vue.filter(
      "currency",
      (
        value: any,
        precision: number = 2,
        lazyPrecision: boolean = false,
        kmb: boolean = false,
        comma: string = ",",
        currency: string = ""
      ) => {
        let sign = "";
        if (value === null || isNaN(value)) return "-";
        if (value < 0) {
          value = -value;
          sign = "-";
        }
        let suffix = "";
        if (kmb) {
          if (value > 5e2) {
            value /= 1000;
            suffix = "k";
          }
          if (value > 5e2) {
            value /= 1000;
            suffix = "m";
          }
        }
        let fixed = (+value).toFixed(precision);
        if (lazyPrecision) fixed = (+fixed).toString();
        fixed = fixed
          .split(".")
          .map((seg, index) => {
            if (index) return seg;
            return seg.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${comma}`);
          })
          .join(".");
        return sign + currency + fixed + suffix;
      }
    );

    Vue.filter("paymentGatewayName", (gateway: any) => {
      return Vue.prototype.$gatewayNames[gateway];
    });

    Vue.filter("productTypeName", (gateway: any) => {
      return Vue.prototype.$productTypeNames[gateway];
    });

    Vue.filter("stockLogStatusName", (gateway: any) => {
      return Vue.prototype.$stockLogStatusNames[gateway];
    });

    Vue.filter("permissionName", (permission: any) => {
      return Vue.prototype.$permissionNames[permission];
    });

    Vue.filter("couponName", (couponSlug: any) => {
      const coupons = Vue.prototype.$config.coupons;
      if (!coupons) {
        return;
      }
      const coupon = coupons.find(
        (c: { slug: string }) => c.slug === couponSlug
      );
      if (coupon) {
        return coupon.name;
      }
      return "-";
    });

    Vue.filter("dow", (v: number) => {
      // @ts-ignore
      return Vue.material.locale.shorterDays[v];
    });
  }
};

export default globalFilters;

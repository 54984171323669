import Notifications from "./Notifications.vue";
import Vue, { VueConstructor } from "vue";

type Notification = { timestamp: Date; message: string };

export const store = Vue.observable({
  notifications: [] as Notification[]
});

export const actions = {
  removeNotification(timestamp: Date) {
    store.notifications = store.notifications.filter(
      (n) => n.timestamp !== timestamp
    );
  },
  addNotification(notification: Notification) {
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + store.notifications.length
    );
    store.notifications = store.notifications.filter(
      (n) => n.message !== notification.message
    );
    store.notifications.push(notification);
  }
};

function notify(
  notification:
    | { timestamp: Date; message: string }
    | { timestamp: Date; message: string }[]
) {
  if (Array.isArray(notification)) {
    notification.forEach((notificationInstance) => {
      actions.addNotification(notificationInstance);
    });
  } else {
    actions.addNotification(notification);
  }
}

const NotificationsPlugin = {
  install(Vue: VueConstructor) {
    Object.defineProperty(Vue.prototype, "$notify", {
      get() {
        return notify;
      }
    });
    Vue.component("Notifications", Notifications);
  }
};

export default NotificationsPlugin;

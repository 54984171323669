export enum BookingStatus {
  PENDING = "pending",
  BOOKED = "booked",
  IN_SERVICE = "in_service",
  PENDING_REFUND = "pending_refund",
  FINISHED = "finished",
  CANCELED = "canceled"
}

export enum Scene {
  PLAY = "play",
  PARTY = "party",
  EVENT = "event",
  GIFT = "gift",
  RETAIL = "retail",
  FOOD = "food",
  CARE = "care",
  OTHER = "other",
  CARD = "card",
  BALANCE = "balance",
  PERIOD = "period",
  MALL = "mall"
}

export const bookingScenes = [
  Scene.PLAY,
  Scene.FOOD,
  Scene.PARTY,
  Scene.EVENT,
  Scene.RETAIL
  // Scene.CARE
];

export enum CardStatus {
  PENDING = "pending", // pending payment for the card
  VALID = "valid", // paid gift card before activated
  ACTIVATED = "activated", // paid non-gift card / activated gift card
  EXPIRED = "expired", // expired period, times empty, credit deposit to user
  CANCELED = "canceled" // never used and will never be activated
}

export enum PaymentGateway {
  Balance = "balance",
  Points = "points",
  Card = "card",
  Contract = "contract",
  CouponCard = "couponcard",
  Coupon = "coupon",
  Scan = "scan",
  Pos = "pos",
  CustomerScan = "customerscan",
  Cash = "cash",
  Shouqianba = "shouqianba",
  Dianping = "dianping",
  MallPos = "mallpos",
  WechatPay = "wechatpay",
  Mall = "mall",
  Pr = "pr",
  Agency = "agency",
  Ar = "ar",
  Cr = "cr",
  Internal = "internal",
  Alipay = "alipay",
  UnionPay = "unionpay",
  BytePay = "byte"
}

export const flowGateways = [
  PaymentGateway.Dianping,
  PaymentGateway.Shouqianba,
  PaymentGateway.Alipay,
  PaymentGateway.WechatPay,
  PaymentGateway.Cash,
  PaymentGateway.Pos,
  PaymentGateway.Mall,
  PaymentGateway.BytePay,
  PaymentGateway.MallPos,
  PaymentGateway.Scan,
  PaymentGateway.CustomerScan
];

export interface PaymentGatewayGroup {
  gateway: PaymentGateway;
  amount: number;
  payCode?: string;
  couponId?: string;
}

export enum Permission {
  DEVELOP = "develop",
  BOSSBOARD = "bossboard",
  DASHBOARD = "dashboard",
  PLAY_BOOKING = "play booking",
  EVENT_BOOKING = "event booking",
  CARE_BOOKING = "care booking",
  FOOD_BOOKING = "food booking",
  GIFT_BOOKING = "gift booking",
  PARTY_BOOKING = "party booking",
  OTHER_BOOKING = "other booking",
  BOOKING_ALL_STORE = "booking all store",
  BOOKING_CREATE = "booking create",
  BOOKING_CANCEL_REVIEW = "booking cancel review",
  CARD = "card",
  CARD_SELL_STORE = "card sell store",
  CARD_SELL_ALL = "card sell all",
  CUSTOMER = "customer",
  PAYMENT = "payment",
  PAYMENT_DOWNLOAD = "payment download",
  PAYMENT_LAST_WEEK = "payment last week",
  PAYMENT_LAST_MONTH = "payment last month",
  PAYMENT_ALL_DATE = "payment all date",
  CASHIER = "cashier",
  NO_STOCK = "no stock",
  CARD_TYPE = "card-type",
  COUPON = "coupon",
  POST = "post",
  EVENT = "event",
  GIFT = "gift",
  PRODUCT = "product",
  STORE = "store",
  STAFF = "staff",
  ROLE = "role",
  CONFIG = "config"
}

export type PermissionKey = keyof typeof Permission;

interface Category {
  uid: string;
  parentUid: number;
  name: string;
}

export interface Flavor {
  uid?: string;
  name: string;
  extraPrice?: number;
  isSuggest?: boolean;
  isDefault?: boolean;
  store?: string;
  disabledStores?: string[];
}

export interface FlavorGroup {
  uid?: string;
  name: string;
  multiple?: boolean;
  required?: boolean;
  flavors: Flavor[];
  disabled?: boolean;
}

export interface ProductInCustomerMenu {
  uid: string;
  categoryUid: number;
  name: string;
  imageUrl?: string;
  sellPrice: number;
  stock: number;
  description: string;
  enable?: number;
  unitName?: string;
  flavorGroups?: FlavorGroup[];
  tags?: string[];
  isSpecialOffer?: boolean;
}

export type Menu = (Category & { products: ProductInCustomerMenu[] })[];

export interface BookingItem {
  _id?: string;
  productUid?: string;
  product?: string;
  quantity: number;
  sellPrice?: number;
  extraPrice?: number;
  amountPaid?: number;
  isSpecialOffer?: boolean;
  productCategory?: string;
  name?: string;
  no?: string;
  brand?: string;
  productImageUrl?: string;
  comment?: string;
  dept?: string;
  checkedAt?: Date;
  deliveredAt?: Date;
}

export interface QueueItem {
  no: number;
  user?: string;
  mobile?: string;
  adultsCount: number;
  kidsCount: number;
  state:
    | "queued"
    | "notified"
    | "calling"
    | "called"
    | "call rejected"
    | "canceled"
    | "settled";
  createdAt: Date;
  notifiedAt?: Date;
  notifiedFor?: string;
  calledAt?: Date;
  calledFor?: string;
  postponedAt?: Date;
}

export interface BookingPrice {
  price: number;
  nonSpecialOfferPrice?: number;
}

export interface CardTypeReward {
  slug: string;
  title: string;
  rewardAt: "issue" | "book" | "checkIn";
  rewardEveryTime: boolean;
  rewardEveryNTimes: number;
  dayType?: "onDaysOnly" | "offDaysOnly" | null;
  weekdays?: number[];
}

interface HistoryPrice {
  price: number;
  from: Date;
}

export interface Supply {
  _id?: string;
  store?: Store;
  providerName?: string;
  productName?: string;
  unit?: string;
  spec: string;
  unitSpec?: number;
  price?: number;
  taxRateInclusive: number;
  shipInclusive: boolean;
  minimumQuantity?: number;
  priceValidFrom: Date;
  priceValidTill: Date;
  priceHistory: HistoryPrice[];
  link?: string;
  remarks?: string;
  disabled?: boolean;
}

export interface FormulaItem {
  product: Product;
  quantity: number;
  unit: string;
  flavorName?: string;
}

export interface SetItem {
  name: string;
  quantity: number;
  isFixedProducts: boolean;
  products: {
    id: string;
    no?: string;
    name: string;
    quantity?: number;
  }[];
}

export interface StockLogItem {
  _id?: string;
  product?: Product;
  desc?: string;
  quantity?: number;
  providerName?: string;
  price?: number;
  supplyId?: string;
  supplyName?: string;
  supplyPrice?: number;
  supplyUnit?: string;
  supplyUnitSpec?: number;
  supplyQuantity?: number;
  supplyLink?: string;
  supplySpec?: string;
  paidAt?: Date;
  paidAmount?: number;
  receivedAt?: Date;
  refundedAt?: Date;
}

export enum StockLogStatus {
  NEW = "new",
  REQUESTED = "requested",
  APPROVED = "approved",
  PAID = "paid",
  SENT = "sent",
  RECEIVED = "received",
  IN_STOCK = "in_stock"
}

export interface Receive {
  _id?: string;
  user?: string;
  userName?: string;
  date?: Date;
  items: string[];
  photoUrls: string[];
}

export interface TicketPrinter {
  dept: string;
  sn: string;
  type: "receipt" | "band";
  bandType?: "normal" | "coupon";
}

export enum CardTypeChannel {
  SELF_RUN_MALL = "srm",
  ONLINE_TRAVEL_AGENT = "ota",
  LOCAL_PERSONAL_AGENT = "lpa",
  STORE_SELF_EXPAND = "sse",
  GLOBAL_MARKETING_COOPERATE = "gmc"
}

export interface Band {
  type: "adult" | "kid";
  no: string;
  name?: string;
  idCardNo?: string;
  signImgUrl?: string;
}

export interface TodoProductGroupItem {
  bookingId: string;
  bookingItemId: string;
  bookingRemarks?: string;
  quantity: number;
  checkInAt: string;
  pagerId?: string;
  tableId?: string;
  comment?: string;
  checkedAt?: Date;
  deliveredAt?: Date;
}

export interface TodoProductGroup {
  product: Partial<Product>;
  items: TodoProductGroupItem[];
  toProduceCount: number;
  toDeliverCount: number;
}

export interface Model {
  _id?: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

type Populated<T> = T | null;

export interface Booking extends Model {
  customer: Populated<User>;
  checkInCustomer: Populated<User>;
  store: Populated<Store>;
  type: Scene;
  date: string;
  checkInAt: string;
  adultsCount: number;
  kidsCount: number;
  socksCount: number;
  bandsPrinted: number;
  bands: Band[];
  photos?: string[];
  faces?: string[];
  status: BookingStatus;
  statusWas: BookingStatus;
  price?: number;
  priceInPoints?: number;
  amountPaid?: number;
  amountRefund?: number;
  kidsRefund?: number;
  adultsRefund?: number;
  refundReason?: string;
  card?: Populated<Card>;
  coupon?: Populated<Coupon>;
  event?: Populated<Event>;
  gift?: Populated<Gift>;
  quantity?: number;
  payments?: Payment[];
  remarks?: string;
  providerData?: Record<string, any>;
  tableId?: string;
  pagerId?: string;
  items?: BookingItem[];
  party?: string;
}

export interface Card extends Model {
  customer: Populated<User>;
  timesLeft: number;
  num?: string;
  status: CardStatus;
  expiresAt: Date;
  expiresAtWas?: Date;
  expiresAtExpected?: Date;
  payments?: Payment[];
  giftCode?: string;
  title: string;
  slug: string;
  type: string;
  isGift: boolean;
  isContract?: boolean;
  adultAsKid?: boolean;
  stores: string[];
  content: string;
  times: number;
  start: Date;
  end: Date;
  balance: number;
  price: number;
  maxTimes: number;
  freeParentsPerKid: number;
  scenes: Scene[];
  couponType?: "discount" | "special";
  renew?: "none" | "daily" | "monthly";
  overPrice?: number;
  discountPrice?: number;
  discountRate?: number;
  fixedPrice?: number;
  providerData?: Record<string, any>;
  productNos?: string[];
  specialOffer?: string;
  rewardedFromCard?: string;
  rewardedFromBooking?: string;
}

export interface CardType extends Model {
  title: string;
  slug: string;
  type: string;
  price: number;
  stores: Populated<Store>[];
  expiresInDays?: number;
  start: Date;
  end: Date;
  dayType?: "onDaysOnly" | "offDaysOnly" | null;
  enabled: boolean;
  isGift: boolean;
  isContract?: boolean;
  channel?: CardTypeChannel | null;
  isLimited?: boolean;
  isCombo?: boolean;
  cancelOnExpire: boolean;
  activeOnGiftReceive: boolean;
  oneTimeCheckIn: boolean;
  adultAsKid: boolean;
  offDayMarkupPrice?: number;
  openForClient: boolean;
  openForReception: boolean;
  openForBar: boolean;
  posterUrl: string;
  posterDenseUrl?: string;
  posterUrls: string[];
  content: string;
  couponSlug?: string;
  customerTags: string[];
  maxPerCustomer?: number;
  quantity?: number;
  rewardCardTypes?: string;
  rewards: Partial<CardTypeReward>[];
  couponType?: string | null;
  scenes: Scene[];
  paymentStore?: Store;

  // type-related properties below
  times: number;
  balance: number;
  balancePriceGroups: { balance?: number; price?: number }[];
  maxTimes: number;
  minKids: number;
  freeParentsPerKid: number;
  overPrice?: number;
  discountPrice?: number;
  discountRate?: number;
  fixedPrice?: number;
  partnerUrl?: string;
}

export interface Client extends Model {
  name: string;
  no?: string;
}

export interface Coupon extends Model {
  title: string;
  slug?: string;
  type: string;
  scene: Scene;
  stores: string[];
  content: string;
  adultsCount: number;
  kidsCount: number;
  hours?: number | null;
  price: number;
  priceThirdParty: number;
  freeParentsPerKid: number;
  start: Date;
  end: Date;
  enabled: true;
  rewards: Partial<CardTypeReward>[];
  overPrice?: number;
  overQuantity?: number;
  discountPrice?: number;
  discountRate?: number;
  fixedPrice?: number;
  setFoods?: string[];
  productNos?: string[];
  oneTimeCheckIn?: boolean;
  keepTimesOnAmendCard?: boolean;
  adultAsKid?: boolean;
  writeOffAsStoreFlow?: boolean;
  offDayMarkupPrice?: number;
}

export interface Config {
  sockPrice?: number;
  extraParentFullDayPrice?: number;
  kidFullDayPrice?: number;
  freeParentsPerKid?: number;
  user?: User;
  stores?: Store[];
  cardTypes?: CardType[];
  coupons?: Coupon[];
  roles?: Role[];
  foodMenuOrder?: Record<string, number>;
  specialFoodMenus?: { name: string }[];
  couponGroups?: { name: string; color: string }[];
  stockUsages?: string[];
}

export interface ConfigItem extends Model, Record<string, any> {
  desc: string;
  value: any;
}

export interface Event extends Model {
  title: string;
  tags: string[];
  ipCharacter: string;
  content?: string;
  posterUrl: string | null;
  kidsCountMax: number | null;
  kidsCountLeft: number | null;
  props?: Record<string, any>;
  priceInPoints: number;
  price?: number;
  date: Date;
  store: Populated<Store>;
  order: number;
  kidAgeRange?: string;
}

export interface File extends Model {
  uri: string;
  thumbnailUrl: string;
  name: string;
  url: string;
}

export interface Gift extends Model {
  title: string;
  content: string;
  posterUrl: string | null;
  quantity: number;
  priceInPoints: number;
  price?: number;
  store: Populated<Store>;
}

export interface Log extends Model {
  booking?: string;
  user?: string;
  type: string;
  content: string;
  createdBy?: User;
  waived?: boolean;
}

export interface Payment extends Model {
  scene: Scene;
  customer: Populated<User>;
  amount: number;
  amountForceDeposit?: number;
  amountDeposit?: number;
  amountInPoints?: number;
  assets: number;
  debt: number;
  revenue: number;
  refundedAmount?: number;
  paid: boolean;
  pending?: boolean;
  title: string;
  booking?: string;
  card?: string;
  stockLog?: string;
  gateway: PaymentGateway;
  gatewayData: { [key: string]: any };
  original?: string;
  store?: Store;
}

export interface Post extends Model {
  title: string;
  slug?: string;
  content: string;
  tags: string[];
  posterUrl: string | null;
  target?: string;
  author: Populated<User>;
  start?: null | Date;
  end?: null | Date;
}

export interface Product extends Model {
  no?: string;
  name: string;
  py: string;
  alias: string;
  unit: string;
  type: string;
  categories: string[];
  brand?: string;
  posterUrl?: string;
  sellPrice?: number;
  description?: string;
  flavorGroups?: FlavorGroup[];
  tags: string[];
  isSpecialOffer: boolean;
  supplies: Partial<Supply>[];
  formula: Partial<FormulaItem>[];
  rawFormula: FormulaItem[];
  setItems: SetItem[];
  unitSpec?: number;
  pospalUid?: string;
  isSelfMade?: boolean;
  buyPrice?: number;
  isSet?: boolean;
  rewards: Partial<CardTypeReward>[];
}

export interface ProductCategory extends Model {
  name: string;
  parent: string;
  order: number;
  openForClient?: boolean;
  openHours?: string;
  openDaysOfWeek: number[];
}

export interface Provider extends Model {
  name: string;
  officialName: string;
  no?: string;
  payOn: "post" | "pre" | "order";
}

export interface Role extends Model {
  name: string;
  permissions: Permission[];
}

export interface Staff extends Model {
  name: string;
  store: Store;
  dept: string;
  level: string;
}

export interface Stock extends Model {
  product: Product;
  store: Store;
  dept: "reception" | "bar";
  usage: string;
  quantity: number;
  amount: number;
  lastVerifiedAt: Date;
  lastVerifiedBy: User;
}

export interface StockLog extends Model {
  status: StockLogStatus;
  items: StockLogItem[];
  store: Store;
  dept: string;
  fromStore?: string;
  fromDept?: string;
  createdBy: User;
  responsibleBy: User;
  reason: string;
  usage?: string;
  remarks: string;
  amountPaid?: number;
  files?: File[];
  paymentDate?: string;
  receiveDate?: string;
  dateStart?: string;
  dateEnd?: string;
  payments?: Payment[];
  receives?: Receive[];
  inStockAt?: Date;
}

export interface Store extends Model {
  name: string;
  address: string;
  phone: string;
  content: string;
  posterUrl: string;
  partyRooms: number;
  dailyLimit: {
    common: [];
    coupon: [];
    dates: { date?: string; group?: string; limit?: number }[];
  };
  ip: string;
  foodMenu?: Menu;
  ticketPrinters?: TicketPrinter[];
  order: number;
  doorsInitialized?: boolean;
}

export interface User extends Model {
  role?: Populated<Role>;
  login?: string;
  password?: string;
  name?: string;
  mobile?: string;
  avatarUrl?: string;
  region?: string;
  country?: string;
  isForeigner?: boolean;
  birthday?: string;
  constellation?: string;
  idCardNo?: string;
  openid?: string;
  store?: Populated<Store>;
  stores: string[];
  balanceDeposit?: number;
  balanceReward?: number;
  balance: number;
  points?: number;
  cardType?: string;
  cardNo?: string;
  cards: Card[];
  tags: string[];
  can(...permission: PermissionKey[]): boolean;
}

export interface AuthLoginPostBody {
  login: string;
  password: string;
}

export interface AuthLoginResponseBody {
  token: string;
  user: User;
}

export interface ListQuery {
  order?: string;
  limit?: number;
  skip?: number;
}

export interface ListResponseBase {
  $headers: Record<string, string>;
}

export interface AuthTokenUserIdResponseBody extends AuthLoginResponseBody {}

export interface BookingPostBody extends Booking {}

export interface BookingPutBody extends Booking {}

export interface BookingPostQuery {
  paymentGateway?: PaymentGateway;
  useBalance?: "false";
  adminAddWithoutPayment?: boolean;
}

export interface BookingQuery extends ListQuery {
  status?: string | string[]; // support comma separated values
  customerKeyword?: string;
  type?: string;
  store?: string;
  date?: string;
  customer?: string;
  event?: string;
  gift?: string;
  coupon?: string;
  product?: string;
  cardSlug?: string;
}

export interface BookingPricePostBody extends Booking {}

export interface BookingPriceResponseBody {
  price: number;
  priceInPoints?: number;
}

export interface CardPostBody extends Card {}

export interface CardPutBody extends Card {}

export interface CardPostQuery {
  paymentGateway?: PaymentGateway;
  adminAddWithoutPayment?: boolean;
}

export interface CardQuery extends ListQuery {
  status?: string; // support comma separated values
  customer?: string;
}

export interface CardTypePostBody extends CardType {}

export interface CardTypePutBody extends CardType {}

export interface CardTypeQuery extends ListQuery {}

export interface EventPostBody extends Event {}

export interface EventPutBody extends Event {}

export interface EventQuery extends ListQuery {
  keyword?: string;
  store?: string;
}

export interface GiftPostBody extends Gift {}

export interface GiftPutBody extends Gift {}

export interface GiftQuery extends ListQuery {
  keyword?: string;
  store?: string;
  isCover?: string;
}

export interface PaymentPostBody extends Payment {}

export interface PaymentPutBody extends Payment {}

export interface PaymentQuery extends ListQuery {
  date?: string;
  paid?: "false";
  customer?: string;
  gateway?: PaymentGateway;
  direction?: "payment" | "refund";
}

export interface PostPostBody extends Post {}

export interface PostPutBody extends Post {}

export interface PostQuery extends ListQuery {
  slug?: string;
  tag?: string;
}

export interface StorePostBody extends Store {}

export interface StorePutBody extends Store {}

export interface StoreQuery extends ListQuery {}

export interface UserPostBody extends User {}

export interface UserPutBody extends User {}

export interface UserQuery extends ListQuery {
  keyword: string;
  role: string;
  membership: string[];
  cardTypes: string[];
}

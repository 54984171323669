<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <!-- <li>
            <a href="https://www.creative-tim.com">Creative Tim</a>
          </li>
          <li>
            <a href="https://creative-tim.com/presentation">
              About Us
            </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com">
              Blog
            </a>
          </li>
          <li>
            <a href="https://www.creative-tim.com/license">
              Licenses
            </a>
          </li> -->
        </ul>
      </nav>
      <div class="copyright text-center">
        Liquisis v{{ $version }}
        <span v-if="$electron"> on App v{{ $electron.app.getVersion() }}</span>
        &copy; 2020-{{ new Date().getFullYear() }}
        <a href="https://codeispoetry.tech/" target="_blank">诗序软件</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>

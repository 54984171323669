










































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  Card,
  CardStatus,
  Booking,
  BookingStatus,
  User,
  CardType,
  PaymentGatewayGroup,
  Scene,
  Store
} from "@/resources/interfaces";
import { CardResource, BookingResource, UserResource } from "@/resources";
import { confirm, promptInput, promptSelect } from "../helpers/sweetAlert";
import moment from "moment";
import PaymentDialog from "./PaymentDialog.vue";
import StoreSelect from "./StoreSelect.vue";

@Component({ components: { PaymentDialog, StoreSelect } })
export default class CardsCard extends Vue {
  @Prop({ default: "card" })
  type!: "card" | "coupon";

  @Prop({ default: () => [] })
  cards!: Card[];

  @Prop({ type: String, default: "" })
  selected!: string;

  @Prop({ default: () => {} })
  activate!: Function;

  @Prop()
  inBooking?: Booking;

  showInvalid = false;

  showTransferCard: null | Card = null;
  transferToMobile = "";
  transferToCustomer: null | User = null;

  showTransferStoreCard: null | Card = null;
  transferToStore: null | Store = null;
  transferCardSetTitle = "";

  upgradeCard: null | Card = null;
  upgradeToCardType: null | CardType = null;

  get validTransfer() {
    return this.transferToCustomer || this.transferToMobile.length === 11;
  }

  get validTransferStore() {
    return this.transferToStore;
  }

  @Watch("transferToMobile") async onSearchTransferToCustomer() {
    if (!this.transferToMobile) {
      this.transferToCustomer = null;
      return;
    }
    const users = await UserResource.query({ keyword: this.transferToMobile });
    if (users.length === 1) {
      this.transferToCustomer = users[0];
    } else {
      this.transferToCustomer = null;
    }
  }

  selectCard(card: Card) {
    if (this.type === "card") {
      this.$emit("update:selected", card.id);
    }
    if (this.type === "coupon") {
      this.$emit("update:selected", card.rewardedFromCard);
    }
  }

  isHighlighted(card: Card) {
    if (this.type === "card" && card.id === this.selected) {
      return true;
    }
    if (this.type === "coupon" && card.rewardedFromCard === this.selected) {
      return true;
    }
    return false;
  }

  async transfer() {
    if (!this.transferToMobile || !this.showTransferCard) return;
    if (!this.transferToCustomer) {
      this.transferToCustomer = await UserResource.create({
        mobile: this.transferToMobile
      });
    }
    let giftCode = this.showTransferCard.giftCode;
    if (
      !giftCode &&
      this.$user.can("PLAY_BOOKING", "BOOKING_ALL_STORE", "CARD_SELL_ALL")
    ) {
      giftCode = `${this.showTransferCard.id}-${this.showTransferCard.customer}`;
    }
    if (!giftCode) return;
    await CardResource.create({
      giftCode,
      // @ts-ignore
      customer: this.transferToCustomer.id
    });
    this.showTransferCard = null;
    this.$router.push("/user/" + this.transferToCustomer.id);
  }

  async transferStore() {
    if (!this.showTransferStoreCard) return;
    await CardResource.update(
      {
        id: this.showTransferStoreCard.id,
        transferStore: this.transferToStore?.id,
        setTitle: this.transferCardSetTitle
      },
      {}
    );
    this.$emit("updated");
    this.showTransferStoreCard = null;
  }

  save(card: Card) {
    CardResource.update(
      { id: card.id },
      // @ts-ignore
      { payments: card.payments.split(",") }
    );
  }

  async remove(card: Card) {
    if (!this.$user.can("CARD_SELL_ALL")) return;
    try {
      if (
        !(await confirm(
          "确认删除这张卡",
          `即将删除该客户的 ${card.title}，本操作不可恢复`,
          null,
          "error"
        ))
      )
        return;
      await CardResource.delete({ id: card.id });
      this.$emit("updated");
    } catch (e) {
      console.error(e);
    }
  }

  async refund(card: Card) {
    if (!this.$user.can("CARD_SELL_ALL")) return;
    try {
      const refundAmount = await promptInput(
        "确认对这张卡进行退款",
        `即将对该客户进行退款 ${card.title}，本操作不可恢复，请输入退款金额：`,
        null,
        "error",
        "number",
        card.price,
        v => {
          if (v > card.price) return "退款金额不能超过销售金额";
          if (v < 0) return "无效退款金额";
        }
      );

      if (refundAmount === undefined) return;
      await CardResource.update(
        { id: card.id, refundAmount },
        { status: CardStatus.CANCELED }
      );
      this.$emit("updated");
    } catch (e) {
      console.error(e);
    }
  }

  async upgrade(card: Card) {
    const upgradableCardTypes = this.getUpgradableCards(card);
    try {
      const targetCardTypeSlug = await promptSelect(
        "会员卡升级",
        `将这张会员卡升级为：`,
        upgradableCardTypes.reduce(
          (map, cardType) => ({ ...map, [cardType.slug]: cardType.title }),
          {} as Record<string, string>
        ),
        "升级"
      );

      if (targetCardTypeSlug === undefined) return;

      this.upgradeCard = card;
      this.upgradeToCardType =
        this.$config.cardTypes?.find(ct => ct.slug === targetCardTypeSlug) ||
        null;
    } catch (e) {
      console.error(e);
    }
  }

  resetUpgradeCard() {
    this.upgradeCard = null;
    this.upgradeToCardType = null;
  }

  async confirmUpgradeCard(paymentGroups: PaymentGatewayGroup[]) {
    if (!this.upgradeCard) return;
    await CardResource.create(
      {
        slug: this.upgradeToCardType?.slug,
        customer: this.upgradeCard.customer
      },
      {
        upgradeFromCard: this.upgradeCard.id,
        paymentGateways: paymentGroups
          .filter(g => g.amount)
          .map(
            g => `${g.gateway}:${g.amount}${g.payCode ? "-" + g.payCode : ""}`
          )
          .join(",")
      }
    );
    this.resetUpgradeCard();
    this.$emit("updated");
  }

  async amendForBooking(card: Card) {
    if (!this.inBooking) return;
    if (
      !(await confirm(
        "本单改为次卡支付",
        `将使用 ${card.title} 支付本单`,
        null,
        "warning"
      ))
    )
      return;
    await BookingResource.update(
      { id: this.inBooking.id },
      { card: card.id as any }
    );
    this.$emit("updated");
  }

  async changeExpireDate(card: Card) {
    if (!this.$user.can("CARD_SELL_ALL")) {
      if (card.expiresAtWas) return;
    }
    const expiresAt = await promptInput(
      "更改会员卡过期日期",
      null,
      null,
      "info",
      "text",
      moment(card.expiresAt).format("YYYY-MM-DD"),
      v => {
        if (!moment(v, "YYYY-MM-DD").isValid()) return "请输入正确的日期格式";
      }
    );
    if (!expiresAt) return;
    await CardResource.update({ id: card.id }, { expiresAt });
    this.$emit("updated");
  }

  cardStoreName(card: Card) {
    const stores = this.$stores.filter(s => card.stores.includes(s.id));
    return stores.length
      ? stores.map(s => s.name.substr(0, 2)).join(" ")
      : "通用";
  }

  cardRemovable(card: Card) {
    if (!this.$user.can("CARD_SELL_ALL")) return false;
    if (card.payments && card.payments.some(p => p.paid)) return false;
    if (card.status === CardStatus.ACTIVATED) {
      if (["coupon", "partner", "period", "balance"].includes(card.type)) {
        return true;
      }
      if (card.type === "times" && card.times === card.timesLeft) {
        return true;
      }
    }
    if (card.status === CardStatus.VALID) {
      return true;
    }
    return false;
  }

  cardRefundable(card: Card) {
    if (!this.$user.can("CARD_SELL_ALL")) return false;
    if (!card.payments || !card.payments.some(p => p.paid)) return false;
    if (card.status === CardStatus.ACTIVATED) {
      if (
        ["coupon", "partner", "period", "balance", "rights"].includes(card.type)
      ) {
        return true;
      }
      if (card.type === "times" && card.timesLeft > 0) {
        return true;
      }
    }
    if (card.status === CardStatus.VALID) {
      return true;
    }
    return false;
  }

  getUpgradableCards(card: Card) {
    return (
      this.$config.cardTypes?.filter(
        ct =>
          (this.$user.can("BOOKING_ALL_STORE")
            ? ct.price >= card.price
            : ct.price > card.price) &&
          ct.slug !== card.slug &&
          ct.type === card.type &&
          card.scenes.every(s => ct.scenes.includes(s)) &&
          (ct.times >= card.times ||
            (ct.times === undefined && card.times === undefined)) &&
          (!ct.stores.length ||
            card.stores.some(s => ct.stores.map(st => st?.id).includes(s)))
      ) || []
    );
  }

  cardUpgradable(card: Card) {
    if (!card.price) return false;
    if (
      (card.status === CardStatus.ACTIVATED ||
        // card createdAt today can upgrade even if expired (timesLeft=0)
        (card.status === CardStatus.EXPIRED &&
          (card.times < 5 ||
            moment(card.createdAt).valueOf() >=
              moment().startOf("day").valueOf() ||
            this.$user.can("DEVELOP")))) &&
      ["times", "period"].includes(card.type) &&
      this.getUpgradableCards(card).length
    ) {
      return true;
    }
    return false;
  }

  cardAmendable(card: Card) {
    if (
      !this.inBooking?.id ||
      this.inBooking.status === BookingStatus.CANCELED ||
      this.inBooking.card ||
      this.inBooking.type !== Scene.PLAY ||
      card.status !== CardStatus.ACTIVATED
    ) {
      return false;
    }
    // if (card.specialOffer !== "adult" && !this.inBooking.kidsCount) {
    //   return false;
    // }
    if (
      !card.stores.includes(this.inBooking.store?.id || "") &&
      !this.$user.can("BOOKING_ALL_STORE")
    )
      return false;
    if (
      card.specialOffer === "adult" &&
      this.inBooking.adultsCount &&
      !this.inBooking.kidsCount
    ) {
      return true;
    }
    if (!["times", "period"].includes(card.type) || !card.price) return false;
    if (
      !this.$user.can("DEVELOP") &&
      !this.$user.can("BOOKING_ALL_STORE") &&
      this.inBooking.date < moment(card.createdAt).format("Y-MM-DD")
    ) {
      return false;
    }
    if (this.inBooking.kidsCount > card.maxTimes) return false;
    return true;
  }
}

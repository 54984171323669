<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          v-if="fromApp"
          class="md-round md-simple md-primary"
          @click="backToApp"
        >
          <md-icon>home</md-icon>
          返回 {{ fromApp.name }}
        </md-button>
        <md-button class="md-just-icon md-round md-simple" @click="navBack">
          <md-icon>arrow_back_ios</md-icon>
        </md-button>
        <md-button class="md-just-icon md-round md-simple" @click="navForward">
          <md-icon>arrow_forward_ios</md-icon>
        </md-button>
        <md-button
          class="md-just-icon md-round md-simple"
          @click="openQrcodeScanner"
        >
          <md-icon>qr_code_scanner</md-icon>
        </md-button>
        <md-button
          class="md-just-icon md-round md-simple"
          @click="requestFullscreen"
          v-if="!isFullscreenEnabled"
        >
          <md-icon>fullscreen</md-icon>
        </md-button>
        <md-button
          class="md-just-icon md-round md-simple"
          @click="exitFullscreen"
          v-if="isFullscreenEnabled"
        >
          <md-icon>fullscreen_exit</md-icon>
        </md-button>
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>

            <!-- <li class="md-list-item">
              <a
                href="#/components/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li> -->

            <!-- <md-list-item href="#/pages/user">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item> -->
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import eventBus from "@/helpers/eventBus";
export default {
  data() {
    const fromAppName = window.localStorage.getItem("fromApp");
    const fromAppUrl = window.localStorage.getItem("fromAppUrl");

    const fromApp = fromAppName && {
      name: fromAppName,
      url: fromAppUrl
    };

    return {
      isFullscreenEnabled: false,
      fromApp
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async openQrcodeScanner() {
      eventBus.$emit("openQrcodeScanner");
    },
    async requestFullscreen() {
      await document.body.requestFullscreen();
      this.isFullscreenEnabled = true;
    },
    async exitFullscreen() {
      await document.exitFullscreen();
      this.isFullscreenEnabled = false;
    },
    navBack() {
      this.$router.back();
    },
    navForward() {
      this.$router.forward();
    },
    backToApp() {
      if (!this.fromApp?.url) return;
      window.location.href = this.fromApp.url;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 960px) {
  .md-toolbar {
    min-height: 0;
    padding: 5px 15px 0;
    .md-title {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
    }
    .md-collapse {
      display: none !important;
    }
  }
}
.md-toolbar {
  // z-index: 10;
}
.md-toolbar-section-end {
  justify-content: flex-end;
}
</style>